
import { Vue,Component,Mixins, InjectReactive, Watch, Ref } from "vue-property-decorator";
import TablePagination from "@/mixins/tablePagination";
import Loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import ETable from "@/components/ETable.vue";
import ProductDialog from "./productDialog.vue";
import MallProductList from "./mallProductList.vue";
import ProductDetail from "./productDetail.vue";

import { presCategoryTypeList } from "@/api/prescriptionDb";

@Component({
    components: {
        ETable, ProductDialog,
        MallProductList,
        ProductDetail
    }
})
export default class PrescriptionProductListCom extends Mixins(TablePagination,Loading,Resize) {
    @Ref("ProductDialogRef") ProductDialogRef: ProductDialog;
    @InjectReactive() categoryInfo !: Object;

    @InjectReactive() merchantId !: string|number;

    queryForm = {
        status: "",
        name: "",
    }

    statusOptions = [
        { label: "启用", value: 1 },
        { label: "禁用", value: 0 }
    ]

    tableData = []
    columns = [
        { label: "ID",prop: "id",width: 70,align: "center" },
        { label: "产品信息",prop: "name",minWidth: "150",align: "center" },
        { label: "关联商品",prop: "relationName",align: "center" },
        { label: "产品状态",prop: "",align: "center", formatter: (row) => !row.status ? "禁用" : "启用" },
        { label: "操作人",prop: "modifiedBy",align: "center" },
        { label: "操作时间",prop: "modifiedTime",align: "center" },
        { label: "操作",prop: "action",align: "center" },
    ]

    visible = false;
    relatedDialogVisible = false;
    productDialogVisible = false;

    categoryProductInfo = {} as any;


    mounted() {
        this.windowResize(300)
    }

    search() {
        this.getPrescriptionProductList();
    }

    addProduct() {
        this.visible = true;
    }

    @Watch("categoryInfo.id")
    async getPrescriptionProductList() {
        try {
            this.showLoading();
            // @ts-ignore
            const { data } = await presCategoryTypeList({ ...this.queryForm, categoryType: 2, parentId: this.categoryInfo.id, merchantId: this.merchantId, ownerType: this.merchantId ? 1 : 0 });
            this.tableData = data.list || [];
            this.itemTotal = data.total;
        } catch (error) {
            console.log(error);
        } finally{
            this.hideLoading()
        }
    }

    edit(row) {
        this.visible = true;
        this.ProductDialogRef.productFormData = { ...row, id: row.id, status: row.id ? Boolean(row.status) : true, img: row.img ? [{ url: row.img }] : [] };
    }
    
    relatedProduct(row) {
        this.categoryProductInfo = row;
        this.relatedDialogVisible = true;
    }

    productDetail(row) {
        this.categoryProductInfo = row;
        this.productDialogVisible = true;
    }
}
